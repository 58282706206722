import React from "react";

import { Box, styled, Typography } from "@mui/material";

const StyledSubtitle = styled("div")(({ theme }) => ({
    fontFamily: "Ableneo Regular",
    fontSize: "0.875rem",
    lineHeight: "0.875rem",
    width: "100%",
    textAlign: "right",
    color: theme.palette.secondary.main,
}));

export const Logo: React.FC = () => (
    <Box
        sx={{
            display: "flex",
            justifyContent: "center",
            padding: "8rem 0 8rem 0",
        }}
    >
        <Box sx={{ display: "inline-flex", flexDirection: "column" }}>
            <Typography variant='logoTitle' color='secondary'>
                samo
            </Typography>
            <StyledSubtitle>
                <Typography variant='logoSubtitle' color='secondary'>
                    by ableneo
                </Typography>
            </StyledSubtitle>
        </Box>
    </Box>
);
