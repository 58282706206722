import * as React from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
// import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import { Button, Stack, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useRecoilState } from "recoil";
import { chatHistoryAtom } from "../../../models/chatHistoryAtom";
import { LeftMenuItem } from "../../atoms/left-menu-item";
import { activeChatIdAtom } from "../../../models/activeChatIdAtom";
import { chatHistoryIdsAtom } from "../../../models/chatHistoryIdsAtom";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

// const DrawerHeader = styled("div")(({ theme }) => ({
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "flex-end",
//     padding: theme.spacing(0, 1),
//     ...theme.mixins.toolbar,
// }));

// interface AppBarProps extends MuiAppBarProps {
//     open?: boolean;
// }

// const AppBar = styled(MuiAppBar, {
//     shouldForwardProp: (prop) => prop !== "open",
// })<AppBarProps>(({ theme }) => ({
//     zIndex: theme.zIndex.drawer + 1,
//     background: "#F3FDF6",
// }));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": {
            ...openedMixin(theme),
            color: "#fff",
            backgroundColor: "#006878",
        },
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": {
            ...closedMixin(theme),
            color: "#fff",
            backgroundColor: "#006878",
        },
    }),
}));

interface LayoutProps {
    children: React.ReactNode;
}

export const Navigation = ({ children }: LayoutProps) => {
    const [chatHistory, setChatHistory] = useRecoilState(chatHistoryAtom);
    const [, setChatHistoryIds] = useRecoilState(chatHistoryIdsAtom);
    const [activeChatId, setActiveChatId] = useRecoilState(activeChatIdAtom);
    const [open, setOpen] = React.useState(true);
    const chats = Object.keys(chatHistory);
    const latestSession = chats[chats.length - 1];
    const olderSessions = chats.slice(0, -1);
    const maximumNewChatsLimit =
        chats.filter((item) => chatHistory[item].length === 0).length < 1;

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleAddNewChat = () => {
        if (maximumNewChatsLimit) {
            const newId = new Date().getTime().toString();
            setChatHistory({
                ...chatHistory,
                [newId]: [],
            });
            setActiveChatId(newId);
            setChatHistoryIds([...chats, newId]);
        }
    };

    return (
        <Box sx={{ display: "flex" }}>
            {/* <AppBar open={open}>
                <Toolbar>
                    <Stack spacing={5}>
                        <BotIcon />
                    </Stack>
                </Toolbar>
            </AppBar> */}
            <Drawer
                variant='permanent'
                open={open}
                PaperProps={{ color: "red"}}
            >
                {/* <DrawerHeader></DrawerHeader>
                <Divider /> */}

                <List>
                    <ListItem
                        disablePadding
                        sx={{ display: "block", padding: "0.5rem" }}
                    >
                        <Stack
                            flexDirection={open ? "row" : "column"}
                            justifyContent='space-between'
                        >
                            {open && (
                                <>
                                    <Button
                                        variant='outlined'
                                        color='inherit'
                                        startIcon={<AddIcon />}
                                        sx={{
                                            textTransform: "none",
                                            fontSize: "0.80575rem",
                                            fontStyle: "normal",
                                            fontWeight: 400,
                                            lineHeight: "1.41006rem",
                                            display: "flex",
                                            flexGrow: 1,
                                            justifyContent: "flex-start",
                                        }}
                                        disabled={!maximumNewChatsLimit}
                                        onClick={handleAddNewChat}
                                    >
                                        New Chat
                                    </Button>
                                    <IconButton
                                        color='inherit'
                                        onClick={handleDrawerClose}
                                    >
                                        <ChevronLeftIcon />
                                    </IconButton>
                                </>
                            )}
                            {!open && (
                                <>
                                    <IconButton
                                        color='inherit'
                                        onClick={handleDrawerOpen}
                                    >
                                        <ChevronRightIcon />
                                    </IconButton>
                                    <Tooltip
                                        title='New Chat'
                                        placement='bottom'
                                    >
                                        <IconButton
                                            color='inherit'
                                            disabled={!maximumNewChatsLimit}
                                            onClick={handleAddNewChat}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            )}
                        </Stack>
                    </ListItem>
                    {open && (
                        <>
                            <ListItem
                                disablePadding
                                sx={{ display: "block", padding: "0.5rem" }}
                            >
                                <Typography
                                    variant='caption'
                                    display='block'
                                    gutterBottom
                                >
                                    Latest session
                                </Typography>
                            </ListItem>
                            {latestSession && (
                                <LeftMenuItem
                                    text={
                                        (chatHistory[latestSession][0] &&
                                            chatHistory[latestSession][0]
                                                .content) ||
                                        "New Chat"
                                    }
                                    id={latestSession}
                                    key={latestSession}
                                    onClick={() =>
                                        setActiveChatId(latestSession)
                                    }
                                    selected={activeChatId === latestSession}
                                />
                            )}
                            <ListItem
                                disablePadding
                                sx={{ display: "block", padding: "0.5rem" }}
                            >
                                <Typography
                                    variant='caption'
                                    display='block'
                                    gutterBottom
                                >
                                    Previous week
                                </Typography>
                            </ListItem>
                            {olderSessions.map((item) => (
                                <LeftMenuItem
                                    id={item}
                                    key={item}
                                    text={
                                        chatHistory[item][0] &&
                                        chatHistory[item][0].content
                                    }
                                    onClick={() => setActiveChatId(item)}
                                    selected={item === activeChatId}
                                />
                            ))}
                        </>
                    )}
                </List>
            </Drawer>
            <Box component='main' sx={{ flexGrow: 1 }}>
                {/* <DrawerHeader /> */}
                {children}
            </Box>
        </Box>
    );
};
