import React from "react";

import { Grid } from "@mui/material";
import {
    SuggestionBox,
    SuggestionBoxProps,
} from "../../molecules/suggestion-box/SuggestionBox";

interface Props {
    items: SuggestionBoxProps[];
    onSubmit: (value: string) => void;
}

export const Suggestions: React.FC<Props> = ({ items, onSubmit }) => (
    <Grid container spacing={1}>
        {items.map((item, i) => (
            <Grid
                key={i}
                xs={12}
                sm={4}
                onClick={() => onSubmit(`${item.title} ${item.text}`)}
                item
            >
                <SuggestionBox title={item.title} text={item.text} />
            </Grid>
        ))}
    </Grid>
);
