import { Paper } from "@mui/material";
import React, { ReactNode } from "react";

interface Props {
    children: ReactNode;
    backgroundColor: string;
}

export const IconWrapper: React.FC<Props> = ({ children, backgroundColor }) => (
    <Paper
        sx={{
            background: backgroundColor,
            padding: "0.5rem",
            boxShadow: "none",
            display: "flex",
        }}
    >
        {children}
    </Paper>
);
