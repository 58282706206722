import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    Stack,
    Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Cta } from "../../atoms/cta";

export interface RelatedDocument {
    link: string;
    text: string;
}

export interface RelatedDocumentsProps {
    documents: RelatedDocument[];
}

export const RelatedDocuments: React.FC<RelatedDocumentsProps> = ({
    documents,
}) => {
    return (
        <Card
            variant={"outlined"}
            sx={{
                mt: "1rem",
            }}
        >
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon fontSize='medium' />}
                    aria-controls='panel1a-content'
                    id='panel1a-header'
                >
                    <Typography
                        sx={{
                            fontSize: "0.875rem",
                            lineHeight: "150%",
                        }}
                    >
                        {documents.length} related documents
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack flexDirection='column'>
                        {documents.map(({ link, text }, i) => (
                            <Cta key={i} link={link} text={text} />
                        ))}
                    </Stack>
                </AccordionDetails>
            </Accordion>
        </Card>
    );
};
