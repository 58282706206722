import { useState } from "react";
import { Button, InputBase, Paper } from "@mui/material";

interface InputProps {
    onSubmit: (value: string) => void;
    onChange?: (value: string) => void;
    placeholder: string;
    disabled?: boolean;
    withButton?: boolean;
    customCss?: {};
}

export const Input: React.FC<InputProps> = ({
    onSubmit,
    onChange,
    placeholder,
    disabled,
    withButton,
    customCss,
}) => {
    const [inputValue, setInputValue] = useState<string>("");

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        if(!disabled) {
            event.preventDefault();
            onSubmit(inputValue);

            setInputValue("");
        }
    };

    return (
        <Paper
            component='form'
            variant='elevation'
            elevation={2}
            onSubmit={handleSubmit}
            sx={{
                p: "0.5rem 0.75rem",
                display: "flex",
                alignItems: "center",
                width: "100%",
                border: "1px solid #B3B3B3",
                boxShadow: "4px 4px 14px 0px rgba(0, 0, 0, 0.25)",
                ...customCss,
            }}
        >
            <InputBase
                sx={{
                    ml: 1,
                    flex: 1,
                }}
                placeholder={placeholder}
                inputProps={{ "aria-label": "... or ask Samo a question" }}
                value={inputValue}
                onChange={(e) => {
                    setInputValue(e.target.value);
                    onChange && onChange(e.target.value);
                }}
            />
            {withButton && (
                <Button
                    disabled={disabled}
                    type='submit'
                    color='primary'
                    variant='contained'
                    sx={{
                        color: "#fff",
                        fontWeight: "bold",
                        textTransform: "none",
                        fontSize: "1.125rem",
                        paddingY: "0.25rem",
                        boxShadow: "none",
                    }}
                >
                    Send
                </Button>
            )}
        </Paper>
    );
};
