import { atom } from "recoil";

export interface ChatHistoryItem {
    role: "user" | "assistant" | "query_assistant";
    content: string;
}

export const chatHistoryAtom = atom<Record<string, ChatHistoryItem[]>>({
    key: "chatHistoryAtom",
    default: {},
});
