import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { ChatBubble } from "./components/molecules/chat-bubble";
import { fetchHistory, PromptInput } from "./App";
import { useParams } from "react-router";
import { Box, Stack } from "@mui/system";
import {
    AppBar as MuiAppBar,
    AppBarProps,
    styled,
    Toolbar,
} from "@mui/material";
import BotIcon from "./assets/icons/BotIcon";
import { Link } from "react-router-dom";

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
    background: "#F3FDF6",
}));

const EmbeddedMessage = () => {
    const [message, setMessage] = useState("");
    const { chatId, messageId } = useParams();

    const { mutate: mutateHistory } = useMutation({
        mutationFn: (newQuestion: PromptInput) => fetchHistory(newQuestion),
        onSuccess: (data, variables) =>
            // @ts-ignore
            setMessage((messageId && data[parseInt(messageId)].content) || ""),
    });
    useEffect(() => {
        mutateHistory({
            chat_history: [],
            chat_id: chatId || "",
            question: "",
        });
    }, []);

    return (
        <>
            <AppBar>
                <Toolbar>
                    <Stack spacing={5}>
                        <Link to={"/"}>
                            <BotIcon />
                        </Link>
                    </Stack>
                </Toolbar>
            </AppBar>
            <Box padding={4} marginTop={8}>
                <ChatBubble text={message} isUser={false} />
            </Box>
        </>
    );
};

export default EmbeddedMessage;
