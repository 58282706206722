import SvgIcon from "@mui/material/SvgIcon";

export default function BotIcon() {
    return (
        <SvgIcon viewBox='0 0 25 27'>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width='25'
                height='27'
                viewBox='0 0 25 27'
                fill='none'
            >
                <circle
                    cx='4.61541'
                    cy='19.8591'
                    r='3.67949'
                    stroke='#006877'
                    strokeWidth='1.05128'
                />
                <circle
                    cx='11.9744'
                    cy='4.61553'
                    r='3.67949'
                    stroke='#006877'
                    strokeWidth='1.05128'
                />
                <circle
                    cx='19.3333'
                    cy='20.9103'
                    r='4.73077'
                    stroke='#006877'
                    strokeWidth='1.05128'
                />
                <rect
                    x='5.66675'
                    y='15.7334'
                    width='9.0823'
                    height='1.05128'
                    transform='rotate(-61.2527 5.66675 15.7334)'
                    fill='#006877'
                />
                <rect
                    x='16.8752'
                    y='16.6085'
                    width='9.43211'
                    height='1.05128'
                    transform='rotate(-116.728 16.8752 16.6085)'
                    fill='#006877'
                />
            </svg>
        </SvgIcon>
    );
}
