import React from "react";
import {Route, Routes} from "react-router-dom";
import App from "./App";
import EmbeddedMessage from "./EmbeddedMessage";

export const AppRouter: React.FC = () => {
    return (
        <Routes>
            <Route
                path='/embedMessage/:chatId/:messageId'
                element={<EmbeddedMessage/>}
            />
            <Route path='/' element={<App/>}/>
            <Route path="*" element={<Page404/>}/>
        </Routes>
    );
};

function Page404() {
    return (<div>Page not found.</div>)
}