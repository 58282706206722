import { createTheme } from "@mui/material/styles";
import AbleneoThinWoff from "../fonts/Ableneo-Thin.woff";
import AbleneoThinWoff2 from "../fonts/Ableneo-Thin.woff2";
import AbleneoRegularWoff from "../fonts/Ableneo-Regular.woff";
import AbleneoRegularWoff2 from "../fonts/Ableneo-Regular.woff2";

declare module "@mui/material/styles" {
    interface TypographyVariants {
        logoTitle: React.CSSProperties;
        logoSubtitle: React.CSSProperties;
    }
    interface TypographyVariantsOptions {
        logoTitle: React.CSSProperties;
        logoSubtitle: React.CSSProperties;
    }
}

declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        logoTitle: true;
        logoSubtitle: true;
    }
}

export const theme = createTheme({
    typography: {
        logoTitle: {
            fontSize: "8rem",
            lineHeight: "8rem",
            fontFamily: "Ableneo Thin",
        },
        logoSubtitle: {
            fontFamily: "Ableneo Regular",
            fontSize: "0.875rem",
            lineHeight: "0.875rem",
        },
    },
    palette: {
        primary: {
            main: "#CCD71E",
            light: "#d6df4b",
            dark: "#8e9615",
        },
        secondary: {
            main: "#98D3D9",
            light: "#acdbe0",
            dark: "#6a9397",
            contrastText: "#ffffff",
        },
        text: {
            primary: "#6B6B6B",
        },
    },
    shape: {
        borderRadius: 12,
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
            @font-face {
              font-family: 'Ableneo Thin';
              font-weight: normal;
              font-style: normal;
              src: url(${AbleneoThinWoff2}) format('woff2'), url(${AbleneoThinWoff}) format('woff');
              unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            }
            @font-face {
              font-family: 'Ableneo Regular';
              font-weight: normal;
              font-style: normal;
              src: url(${AbleneoRegularWoff2}) format('woff2'), url(${AbleneoRegularWoff}) format('woff');
              unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            }
            `,
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    border: "1px solid #D9D9D9",
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                            borderWidth: "1px",
                        },
                    },
                },
            },
        },
    },
});
