import React from "react";

import { Container } from "@mui/material";
import { Navigation } from "../navigation";

interface LayoutProps {
    children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children }) => (
    <Navigation>
        <Container
            maxWidth='lg'
            sx={{
                height: "100vh",
                p: 0,
                "@media (min-width: 600px)": {
                    paddingLeft: 0,
                    paddingRight: 0,
                },
            }}
        >
            {children}
        </Container>
    </Navigation>
);
