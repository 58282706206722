import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist({
    key: "chatHistoryIdsAtom",
    storage: localStorage,
});

export const chatHistoryIdsAtom = atom<string[]>({
    key: "chatHistoryIdsAtom",
    default: [],
    effects: [persistAtom],
});
