import { atom } from "recoil";

// const { persistAtom } = recoilPersist({
//     key: "activeChatId",
//     storage: localStorage,
// });

export const activeChatIdAtom = atom<string>({
    key: "activeChatId",
    default: new Date().getTime().toString(),
    // effects: [persistAtom],
});
