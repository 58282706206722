import { Link, Typography } from "@mui/material";
import React from "react";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

interface Props {
    text: string;
    link: string;
}

export const Cta: React.FC<Props> = ({ text, link }) => (
    <Link
        href={link}
        color='#0D99FF'
        display='flex'
        justifyContent='space-between'
        underline='hover'
        padding='0.5rem 0'
        target='_blank'
        rel='noopener'
    >
        <Typography variant='body1'>{text}</Typography>
        <ArrowOutwardIcon />
    </Link>
);
