import React from "react";
import { Card, Typography } from "@mui/material";

export interface SuggestionBoxProps {
    title: string;
    text: string;
}

export const SuggestionBox: React.FC<SuggestionBoxProps> = ({
    title,
    text,
}) => (
    <Card
        variant='outlined'
        sx={{
            padding: "0.75rem 1.125rem",
            height: "100%",
            cursor: "pointer",
            ":hover": { backgroundColor: "#D9D9D9" },
        }}
    >
        <Typography variant='body1' fontWeight='bold'>
            {title}
        </Typography>
        <Typography variant='body1'>{text}</Typography>
    </Card>
);
