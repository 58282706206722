import {keyframes} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;


export const PulsingCircle = () => (
    <Box
        sx={{
            marginTop: '0.5rem',
            width: 10,
            height: 10,
            backgroundColor: '#e8e8e8',
            borderRadius: '50%',
            animation: `${pulse} 2s infinite`,
        }}
    />
);