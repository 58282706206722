import {Alert, Snackbar, styled, Typography} from "@mui/material";
import {Box, Stack} from "@mui/system";
import {useMutation} from "@tanstack/react-query";
import {useEffect, useLayoutEffect, useRef, useState} from "react";
import {useRecoilState} from "recoil";
import axiosInstance from "./api/axiosConfig";
import {ChatBubble} from "./components/molecules/chat-bubble";
import {Input} from "./components/molecules/input";
import {Layout} from "./components/molecules/layout";
import {Logo} from "./components/molecules/logo";
import {Suggestions} from "./components/organisms/suggestions";
import {chatHistoryAtom, ChatHistoryItem} from "./models/chatHistoryAtom";
import {activeChatIdAtom} from "./models/activeChatIdAtom";
import {modifyChatHistory} from "./utils/functions";
import {chatHistoryIdsAtom} from "./models/chatHistoryIdsAtom";
import {FeedbackModal} from "./components/molecules/feedback-modal";
import {FeedbackInput} from "./components/molecules/feedback-modal/FeedbackModal";
import {getApiEndpoint} from "./utils/getApiEndpoint";

const TextLink = styled("span")({
    textDecoration: "underline",
    cursor: "pointer",
});

const SUGGESTIONS = [
    {
        title: "Compare",
        text: "banking apps in Slovakia from the UX point of view",
    },
    {
        title: "Give me instructions",
        text: "for transactions in CZK and EUR",
    },
    {
        title: "Explain and list",
        text: "service charges for mortgage in SK banks",
    },
];

export interface PromptInput {
    chat_history: ChatHistoryItem[] | [];
    chat_id: string;
    question: string;
}

export interface FeedbackSubmitInput extends FeedbackInput {
    chat_id?: string;
    question?: string;
    chat_history?: ChatHistoryItem[] | [];
    answer?: string;
}

const sendFeedback = async (feedback: FeedbackSubmitInput) => {
    const url = `${getApiEndpoint()}/feedback`;
    return await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(feedback),
    });
};

export const fetchHistory = async ({
                                       chat_history,
                                       question,
                                       chat_id,
                                   }: PromptInput) => {
    const {data} = await axiosInstance.post<string>(
        "/history",
        {
            chat_history,
            chat_id,
            question,
        },
        {
            headers: {
                "Content-Type": "application/json",
            },
        }
    );

    return data;
};

function App() {
    const [chatHistory, setChatHistory] = useRecoilState(chatHistoryAtom);
    const [activeChatId] = useRecoilState(activeChatIdAtom);
    const [chatHistoryIds, setChatHistoryIds] =
        useRecoilState(chatHistoryIdsAtom);
    const [open, setOpen] = useState(false);
    const [openFeedbackSnackbar, setOpenFeedbackSnackbar] = useState(false);
    const [streamedData, setStreamedData] = useState<{ [key: string]: string }>(
        {}
    );
    const [loadingStates, setLoadingStates] = useState<{
        [key: string]: boolean;
    }>({});
    const ref = useRef<HTMLDivElement>(null);
    const isPending = loadingStates[activeChatId];
    const isHistory =
        chatHistory[activeChatId] && chatHistory[activeChatId].length !== 0;
    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const {mutate: mutatePrompt} = useMutation({
        mutationFn: async (newQuestion: PromptInput) => {
            try {
                const response = await fetch(
                    `${getApiEndpoint()}/prompt`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            chat_history: newQuestion.chat_history,
                            chat_id: newQuestion.chat_id,
                            question: newQuestion.question || "",
                        }),
                    }
                );

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const reader = response.body?.getReader();
                const textDecoder = new TextDecoder();
                let accumulatedData = "";

                while (true) {
                    // @ts-ignore
                    const {done, value} = await reader.read();

                    if (done) {
                        break;
                    }

                    const chunk = textDecoder.decode(value, {stream: true});
                    accumulatedData += chunk;

                    setStreamedData((prevData) => ({
                        ...prevData,
                        [newQuestion.chat_id]: accumulatedData,
                    }));
                }

                return accumulatedData;
            } catch (error) {
                console.error("Error fetching prompt:", error);
                setOpen(true);
                throw error;
            }
        },
        onSuccess: (data, variables) => {
            setLoadingStates((prev) => ({
                ...prev,
                [variables.chat_id]: false,
            }));

            setChatHistory((prevChatHistory) =>
                modifyChatHistory(prevChatHistory, variables.chat_id, {
                    role: "assistant",
                    content: data || "",
                })
            );

            setStreamedData((prevData) => {
                const {[variables.chat_id]: _, ...rest} = prevData;
                return rest;
            });
        },
        onError: () => setOpen(true),
        mutationKey: [activeChatId],
    });

    const {mutate: mutateFeedback, isPending: isFeedbackPending} =
        useMutation({
            mutationFn: (feedbackData: FeedbackSubmitInput) =>
                sendFeedback(feedbackData),
            onSuccess: () => setOpenFeedbackSnackbar(true),
            onError: () => setOpen(true),
        });

    const {mutate: mutateHistory} = useMutation({
        mutationFn: (newQuestion: PromptInput) => fetchHistory(newQuestion),
        onSuccess: (data, variables) =>
            data.length !== 0 &&
            setChatHistory((prevChatHistory) => {
                return {
                    ...prevChatHistory,
                    [variables.chat_id]: (
                        data as unknown as ChatHistoryItem[]
                    ).filter((item) => item.role !== "query_assistant"),
                };
            }),
    });

    const handleSubmit = (value: string) => {
        if (value) {
            setChatHistoryIds([...chatHistoryIds, activeChatId]);
            setChatHistory((prevChatHistory) =>
                modifyChatHistory(prevChatHistory, activeChatId, {
                    role: "user",
                    content: value,
                })
            );
            setLoadingStates((prev) => ({...prev, [activeChatId]: true}));

            mutatePrompt({
                chat_history: chatHistory[activeChatId] || [],
                chat_id: activeChatId,
                question: value || "",
            });
        }
    };

    useEffect(() => {
        chatHistoryIds.length === 0
            ? setChatHistory({[activeChatId]: []})
            : chatHistoryIds.map((historyId) =>
                mutateHistory({
                    chat_history: [],
                    chat_id: historyId,
                    question: "",
                })
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useLayoutEffect(() => {
        if (chatHistory[activeChatId] && chatHistory[activeChatId].length && ref.current) {
            const scrollOffset = 10; // small buffer in pixels
            const isScrolledToBottom = ref.current.scrollHeight - ref.current.scrollTop <= ref.current.clientHeight + scrollOffset;

            if (isScrolledToBottom) {
                ref.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                });
            }
        }
    }, [chatHistory, activeChatId, streamedData]);

    return (
        <Layout>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity='error'>
                    Ľutujeme, ale chatbot momentálne nie je dostupný.
                </Alert>
            </Snackbar>
            <Snackbar
                open={openFeedbackSnackbar}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity='success'>
                    Ďakujeme za spätnú väzbu. Niekto sa na to čoskoro pozrie..
                </Alert>
            </Snackbar>
            <Stack
                flexDirection='column'
                spacing={3}
                sx={{height: "100%", position: "relative"}}
                justifyContent='space-between'
            >
                <Stack
                    flexDirection='column'
                    spacing={2}
                    sx={{overflow: "scroll", padding: "1rem"}}
                >
                    {!isHistory && <Logo/>}
                    {chatHistory[activeChatId] &&
                        chatHistory[activeChatId].map((item, i) => (
                            <ChatBubble
                                key={i.toString()}
                                text={item.content}
                                isUser={item.role === "user"}
                                chatId={activeChatId}
                                messageId={i.toString()}
                            />
                        ))}
                    {isPending && (
                        <ChatBubble
                            text={streamedData[activeChatId]}
                            isUser={false}
                            inProgress={true}
                        />
                    )}
                    <Box
                        sx={{
                            paddingBottom: "6.5rem",
                        }}
                    />
                    <div ref={ref}/>
                </Stack>
                <Stack
                    flexDirection='column'
                    spacing={2}
                    sx={({breakpoints}) => ({
                        padding: "1rem",
                        position: "absolute",
                        bottom: 0,
                        left: 0,

                        backdropFilter: 'saturate(180%) blur(15px)',
                        right: 0,
                        [breakpoints.up("md")]: {
                            padding: "0 8rem 1rem",
                        },
                    })}
                >
                    {Object.keys(chatHistory).length !== 0 && !isHistory && (
                        <Suggestions
                            items={SUGGESTIONS}
                            onSubmit={handleSubmit}
                        />
                    )}
                    <Input
                        onSubmit={handleSubmit}
                        placeholder={
                            isHistory
                                ? "Message Samo..."
                                : "... or ask Samo a question"
                        }
                        disabled={isPending}
                        withButton
                    />
                    <Typography
                        variant='body2'
                        sx={{paddingLeft: "1.125rem"}}
                    >
                        Conversations are recorded on the server. Avoid sharing
                        sensitive data. Report issues{" "}
                        <FeedbackModal
                            onSubmit={(value: FeedbackSubmitInput) => {
                                const history = chatHistory[activeChatId];
                                mutateFeedback({
                                    chat_id: activeChatId,
                                    reporter: value.reporter,
                                    feedback: value.feedback,
                                    chat_history: history || [],
                                    question:
                                        history[history.length - 2].content ||
                                        "",
                                    answer:
                                        history[history.length - 1].content ||
                                        "",
                                });
                            }}
                            isPending={isFeedbackPending}
                        >
                            <TextLink>here</TextLink>
                        </FeedbackModal>
                        .
                    </Typography>
                </Stack>
            </Stack>
        </Layout>
    );
}

export default App;
