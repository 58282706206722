import { ListItem, ListItemButton, Typography } from "@mui/material";
import React from "react";
// import StarIcon from "@mui/icons-material/Star";
// import StarBorderIcon from "@mui/icons-material/StarBorder";
// import DeleteIcon from "../../../assets/icons/DeleteIcon";

interface Props {
    id: string;
    text: string;
    selected?: boolean;
    favorite?: boolean;
    onClick: () => void;
}

export const LeftMenuItem: React.FC<Props> = ({
    id,
    text,
    selected,
    onClick,
}) => (
    <ListItem
        key={id}
        sx={{
            padding: "0.5rem",
            display: "block",
            cursor: "pointer",
        }}
    >
        <ListItemButton
            sx={{
                color: "#fff",
                background: selected ? "#228593" : "transparent",
                borderRadius: "0.25rem",
                // padding: "0 0.25rem",
                padding: "0.25rem",
                boxShadow: "none",
                display: "flex",
                alignItems: "center",
                '&:hover': {
                    backgroundColor: '#228593', // Changes the background color to red on hover
                },
            }}
            onClick={onClick}
        >
            {/* {favorite ? (
                <StarIcon sx={{ margin: "0.25rem" }} />
            ) : (
                <StarBorderIcon sx={{ margin: "0.25rem" }} />
            )} */}
            <Typography
                variant='body2'
                noWrap
                sx={{
                    margin: "0 0.25rem",
                    textOverflow: "ellipsis",
                    flexGrow: 1,
                }}
            >
                {text}
            </Typography>
            {/* <div style={{ padding: "0.25rem" }}>
                <DeleteIcon />
            </div> */}
        </ListItemButton>
    </ListItem>
);
