import React, {FC, useState} from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DocumentIcon from "../../assets/icons/DocumentIcon";


export const CopyToClipboardButton:FC<CopyToClipboardButton> = ({ textToCopy, tooltipTitle = "Copied to clipboard!" }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const handleClick = () => {
        navigator.clipboard.writeText(textToCopy);
        setTooltipOpen(true);
        setTimeout(() => setTooltipOpen(false), 2000); // Hide tooltip after 2 seconds
    };

    return (
        <Tooltip
            open={tooltipOpen}
            title={tooltipTitle}
            leaveDelay={200}
        >
            <IconButton onClick={handleClick}>
                <DocumentIcon />
            </IconButton>
        </Tooltip>
    );
};

type CopyToClipboardButton = {
    textToCopy:string;
    tooltipTitle?:string;
}

