import { ChatHistoryItem } from "../models/chatHistoryAtom";

export const modifyChatHistory = (
    prevChatHistory: Record<string, ChatHistoryItem[]>,
    id: string,
    newItem: ChatHistoryItem
) => {
    const updatedHistory = { ...prevChatHistory };
    const messages = updatedHistory[id] ? [...updatedHistory[id]] : [];
    messages.push(newItem);
    updatedHistory[id] = messages;
    return updatedHistory;
};

export const extractDocuments = (text: string) => {
    const regex = /<a\s+[^>]*href=["']([^"']+)["'][^>]*>/;
    const linksArray = [];

    if (text) {
        let match;
        while ((match = text.match(regex)) !== null) {
            const href =
                match[1].replace(/^\./, "").replace(".txt", ".pdf") || "";
            const fileName = href.split("/").pop()?.split(".")[0] || "";

            linksArray.push({
                link: process.env.REACT_APP_ROOT_BASE_URL + href,
                text: fileName,
            });

            // @ts-ignore
            text = text.substring(match.index + match[0].length);
        }
    }

    return linksArray;
};
