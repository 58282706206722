import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {theme} from "./utils/muiThemeUtils";
import {QueryClientProvider} from "@tanstack/react-query";
import {queryClient} from "./api/reactQueryConfig";
import {RecoilRoot} from "recoil";
import {AppRouter} from "./AppRouter";
import {HashRouter} from "react-router-dom";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <React.StrictMode>
        <HashRouter>
            <QueryClientProvider client={queryClient}>
                <RecoilRoot>
                    <ThemeProvider theme={theme}>
                        <CssBaseline/>
                        <AppRouter/>
                    </ThemeProvider>
                </RecoilRoot>
            </QueryClientProvider>
        </HashRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
