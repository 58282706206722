import React, {useEffect, useState} from "react";
import {Card, Grid, IconButton, Skeleton, Stack, Typography,} from "@mui/material";
import UserIcon from "../../../assets/icons/UserIcon";
import {IconWrapper} from "../../atoms/icon-wrapper";
import BotIcon from "../../../assets/icons/BotIcon";
import {RelatedDocument, RelatedDocuments} from "./RelatedDocuments";
import {extractDocuments} from "../../../utils/functions";
import LinkIcon from "../../../assets/icons/LinkIcon";
import {Link} from "react-router-dom";
import {CopyToClipboardButton} from "../../atoms/CopyToClipboardButton";
import {PulsingCircle} from "../../atoms/PulsingCircle";


export interface ChatBubbleProps {
    text?: string;
    isUser?: boolean;
    isLoading?: boolean;
    inProgress?: boolean;
    chatId?: string;
    messageId?: string;
}

export const ChatBubble: React.FC<ChatBubbleProps> = ({
                                                          text,
                                                          isUser,
                                                          isLoading,
                                                          chatId,
                                                          messageId,
                                                      }) => {
    const [extractedLinks, setExtractedLinks] = useState<
        RelatedDocument[] | []
    >([]);
    const formattedText = text?.replace(/\n/g, "<br>") || "";

    useEffect(() => {
        setExtractedLinks(extractDocuments(text as string));
    }, [text]);

    return (
        <Card
            variant={isUser ? "outlined" : "elevation"}
            sx={{
                padding: "1.5rem 2.25rem",
                overflow: "visible",
                boxShadow: isUser
                    ? "none"
                    : "1px 1px 14px 0px rgba(0, 0, 0, 0.25)",
            }}
        >
            <Grid container spacing={2} wrap='nowrap'>
                <Grid flexGrow={0} item>
                    {isLoading ? (
                        <Skeleton variant='circular' width={48} height={48}/>
                    ) : (
                        <IconWrapper
                            backgroundColor={isUser ? "#98D3D9" : "#CCD71E"}
                        >
                            {isUser ? <UserIcon/> : <BotIcon/>}
                        </IconWrapper>
                    )}
                </Grid>
                <Grid flexGrow={1} item>
                    {isLoading ? (
                        <Skeleton
                            variant='rectangular'
                            width='100%'
                            height={120}
                        />
                    ) : (
                        formattedText ?
                            <Typography
                                variant='body1'
                                dangerouslySetInnerHTML={{__html: formattedText}}
                            />
                            : <PulsingCircle/>

                    )}
                    {extractedLinks.length !== 0 && (
                        <RelatedDocuments documents={extractedLinks}/>
                    )}
                </Grid>
                {!isUser && (
                    <Grid flexGrow={0} item>
                        <Stack
                            flexDirection='row'
                            justifyContent='space-evenly'
                        >
                            {!isLoading && (
                                <CopyToClipboardButton textToCopy={formattedText
                                    .replace(/<[^>]*>/g, " ")
                                    .replace(/\s{2,}/g, " ")
                                    .trim()}/>
                            )}
                            {chatId && messageId && (
                                <IconButton sx={{m: 0}}>
                                    <Link
                                        to={`/embedMessage/${chatId}/${messageId}`}
                                    >
                                        <LinkIcon/>
                                    </Link>
                                </IconButton>
                            )}
                        </Stack>
                    </Grid>
                )}
            </Grid>
        </Card>
    );
};
