import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Input } from "../input";
import { Button, CircularProgress } from "@mui/material";
import { FeedbackSubmitInput } from "../../../App";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    border: "none",
    bgcolor: "background.paper",
    boxShadow: "4px 4px 14px 0px rgba(0, 0, 0, 0.25)",
    p: 4,
    borderRadius: "0.5rem",
    outline: 0,
};

export interface FeedbackInput {
    feedback: string;
    reporter: string;
}

interface FeedbackModalProps {
    children: React.ReactNode;
    onSubmit: (value: FeedbackSubmitInput) => void;
    isPending?: boolean;
}

export const FeedbackModal: React.FC<FeedbackModalProps> = ({
    children,
    onSubmit,
    isPending,
}) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [feedbackValue, setInputValue] = React.useState("");
    const [reporterValue, setReporterValue] = React.useState("");

    const handleInputValueChange = (value: string) => {
        setInputValue(value);
    };

    const handleReporterValueChange = (value: string) => {
        setReporterValue(value);
    };

    const handleSubmit = () => {
        const reportData: FeedbackInput = {
            feedback: feedbackValue,
            reporter: reporterValue,
        };
        if (feedbackValue && reporterValue) {
            console.log(reportData);
            onSubmit(reportData);
        }
        handleClose();
    };

    return (
        <>
            <span onClick={handleOpen}>{children}</span>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box sx={style}>
                    <Typography
                        id='modal-modal-title'
                        variant='h6'
                        component='h2'
                    >
                        Report issues
                    </Typography>
                    <Input
                        onSubmit={handleSubmit}
                        onChange={handleInputValueChange}
                        placeholder='write your feedback and enter'
                        disabled={isPending}
                        customCss={{ boxShadow: "none", mt: 3, mb: 2 }}
                    />
                    <Input
                        onSubmit={handleSubmit}
                        onChange={handleReporterValueChange}
                        placeholder='who is reporting'
                        disabled={isPending}
                        customCss={{ boxShadow: "none", mt: 3, mb: 2 }}
                    />
                    <Box display='flex' justifyContent='flex-end'>
                        <Button
                            variant='contained'
                            color='secondary'
                            onClick={handleSubmit}
                            disabled={!feedbackValue || !reporterValue}
                            sx={{
                                fontWeight: "bold",
                                textTransform: "none",
                                fontSize: "1.125rem",
                                paddingY: "0.25rem",
                                boxShadow: "none",
                            }}
                        >
                            {isPending ? (
                                <CircularProgress
                                    size={18}
                                    sx={{ color: "#fff" }}
                                />
                            ) : (
                                "Submit"
                            )}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};
